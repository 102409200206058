import { Link } from 'gatsby'
import {
  personeelTransparancyIllustration,
  salarisIllustration
} from 'images'
import React from 'react'

import config from '../../../config'
const { appUrl, signupRoute } = config

import CtaRow from 'components/cta-row'
import FaqDropdown from 'components/faq-dropdown'
import NoHiddenFeesHolding from 'components/no-hidden-fees-holding'
import PricingColumn from 'components/pricing-column'
import Helmet from 'react-helmet'
import Layout from '../../../layouts'

const features = [
  'Digitale loonstrookjes',
  'Gebruikelijke loonregeling',
  'Jaaropgaven DGA',
  'Automatische loonaangifte bij de Belastingdienst',
  'Onbeperkt wijzigingen doorvoeren',
  'Onbeperkt financiële rapportages draaien',
  'Gratis toegang voor je boekhouder',
  'Geen opstartkosten',
]

const dummyTable_block = {
  title: 'Onze oplossing bevat:',
  packageName: 'DGA-verloning',
  packageDescription: 'Speciaal voor DGA’s',
  price: 15.95,
  prijsWerkenmer: 0,
  features: [
    {
      description: '100% Online salarisadministratie',
      active: true
    },
    { description: 'Automatische loonaangifte', active: true },
    { description: 'Koppeling met je boekhoudpakket', active: true },
    { description: 'Uitstekende support', active: true },
    { description: 'Declaraties', active: false, type: 'ADD-ON' }
  ],
}

const dummyTable_column = {
  title: 'Onze oplossing bevat:',
  packageName: 'DGA-verloning',
  packageDescription: 'Speciaal voor DGA’s',
  price: 15.95,
  button: 'primary',
  prijsWerknemer: 15.95,
  features: [
    {
      description: '100% Online salarisadministratie',
      active: true
    },
    { description: 'Automatische loonaangifte', active: true },
    { description: 'Koppeling met je boekhoudpakket', active: true },
    { description: 'Uitstekende 24/7 support', active: true },
    { description: 'Declaraties', active: false, type: 'ADD-ON' }
  ],
}

const questions = [
  {
    title: 'Hoe lang is de proefperiode precies?',
    id: 'hoe-lang-proefperiode',
    content:
      'Na het aanmaken van een account, kun je Employes zo lang als je wilt gratis en vrijblijvend uitproberen in de vorm van een demo-versie. Pas wanneer je aangeeft dat je daadwerkelijk met Employes aan de slag wil, zetten we je account op actief.',
    link: '',
  },
  {
    title: 'Hoe ga ik van start met Employes?',
    id: 'hoe-start-ik',
    content:
      'Nadat je een account hebt aangemaakt, begeleiden we je stap voor stap door de applicatie. Tijdens deze stappen, kun je alle relevante gegevens voor je salarisadministratie invullen. Daarna kun je binnen een paar minuten al de eerste loonstrook bekijken. Wil je daarna overstappen? Dan kun je aangeven dat je je account wilt activeren.',
    link: '',
  },
  {
    title: 'Hoe werkt het overstappen?',
    id: 'hoe-werkt-overstappen',
    content:
      'Om ervoor te zorgen dat je met Employes aan de slag kunt, hebben wij de volgende informatie nodig: de meest recente loonstroken van je werknemer(s), de laatste loonaangifte en de loonjournaalpost. Ons team regelt dat die informatie in de applicatie wordt ingeladen zodat je de salaris van de volgende maand automatisch in Employes kunt verwerken.',
    link: '',
  },
  {
    title: 'Voldoet Employes aan de AVG?',
    id: 'employes-avg',
    content:
      'Ja, met Employes voldoe je aan de regels die gelden voor de verwerking van persoonsgegevens op basis van de Algemene verordening gegevensbescherming.',
    link: '',
  },
]

class PersoneelPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: '',
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if (id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id,
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="tarieven animated fadeInPage">
          <Helmet>
            <title>
              Tarieven salarisadministratie holding en DGA | Employes | vanaf € 15,95 per maand
            </title>
            <meta
              name="description"
              content="De tarieven salarisadministratie voor een holding en voor een DGA zijn inclusief. Geen verborgen kosten dus. Probeer nu gratis!"
            />
            <meta
              itemprop="name"
              content="Tarieven salarisadministratie holding en DGA | Employes | vanaf € 15,95 per maand"
            />
            <meta
              itemprop="description"
              content="De tarieven salarisadministratie voor een holding en voor een DGA zijn inclusief. Geen verborgen kosten dus. Probeer nu gratis!"
            />
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <div className="container-md padding-xxl">
            <div className="grid center text-center">
              <div className="col-7">
                <p className="eyebrow">Tarieven</p>
                <h1>Salarisadministratie DGA en holding</h1>
                <p className="streamer large center">
                  Aan je DGA-verloning wil je natuurlijk zo min mogelijk tijd en
                  geld kwijt zijn. Met Employes kun je dit eenvoudig en snel
                  regelen voor een scherp tarief, inclusief automatische
                  loonaangifte.
                </p>
              </div>
            </div>
          </div>

          <div className="pricing-columns">
            <div className="backdrop"></div>

            <div className="container-sm padding-s-bottom">
              <div className="grid padding-m-bottom">
                <PricingBlock
                  data={dummyTable_block}
                  className="col-12 content-box no-pad blue"
                  headerIllustration={salarisIllustration}
                />
                <PricingColumn
                  data={dummyTable_column}
                  className="col-12 content-box no-pad blue show-mobile"
                  headerIllustration={salarisIllustration}
                />
              </div>

              <div className="grid yg center text-center streamer">
                <div className="col-12">
                  <p>
                    Heb je ook een werkmaatschappij?{' '}
                    <Link
                      to="/tarieven/bedrijf-met-personeel/"
                      className="link inline"
                      href="#"
                    >
                      Bekijk onze tarieven
                    </Link>
                    voor bedrijven.
                  </p>
                  {/*<p>*Dit is het tarief dat geldt vanaf 2023.</p>*/}
                </div>
              </div>
            </div>
          </div>

          <NoHiddenFeesHolding
            illustration={personeelTransparancyIllustration}
            features={features}
          />

          <CtaRow
            light={true}
            internallink={true}
            linkTo="/product/vergelijken/"
            text="Vergelijk met andere aanbieders."
            subtext="Er zijn veel verschillende manieren om de salarisadministratie van je bedrijf te regelen, bijvoorbeeld via een boekhouder of een bestaand salarispakket. Bekijk de beste oplossing."
            btnText="Bekijk de verschillen "
            eyebrow="Vergelijken"
          />

          <div className="container-md padding-xxl">
            <div className="grid">
              <div className="col-6">
                <h6 className="eyebrow">Overstappen</h6>
                <h2>Veelgestelde vragen over een DGA-verloning</h2>
                <p>
                  Overstappen van de ene naar de andere oplossing voor je
                  salarisadministratie kan nogal wat vragen oproepen. Wij zetten
                  de veelgestelde vragen en antwoorden voor je op een rij zodat
                  je doordacht je keuze kunt maken.
                </p>
              </div>

              <div className="col-6 faq-block">
                {questions.map((question, idx) => (
                  <FaqDropdown
                    question={question}
                    idx={idx}
                    setActive={this.setActive}
                    activeID={this.state.activeID}
                    key={idx}
                  />
                ))}
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

const PricingBlock = props => (
  <div
    className={`pricing-block ${
      props.className !== undefined ? props.className : ''
    }`}
  >
    <div className="col-6 no-pad pricing-content">
      <div className="column-heading">
        <div className="grid yg center">
          <div className="col-8">
            <h3 className="no-margin">{props.data.packageName}</h3>
            <h5>{props.data.packageDescription}</h5>
          </div>

          <div className="col-4">
            <img
              src={props.headerIllustration}
              alt="illustratie prijskaartjes"
            />
          </div>
        </div>
      </div>

      <div className="column-footer">
        <div className="grid center text-center">
          <div className="col-12">
            <div className="price-container">
              <span className="price">
                <h1 className="no-margin padding-xxs-bottom">
                  {' '}
                  15,95
                  <small> per loonstrook</small>
                </h1>
              </span>
            </div>
            <p className="padding-m-bottom">
              Ook voor nihil- en nulaangiftes (excl. btw)*
            </p>
            <a
              href={appUrl + signupRoute}
              className="btn primary lg center"
              rel="nofollow"
            >
              Probeer nu gratis
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="col-6 feature-content">
      <h6>{props.data.title}</h6>

      <div className="rows">
        {props.data.features.map((feature, idx) => (
          <div key={`${feature}-${idx}`} className="row">

            {feature.active === true ? (

              <span className="row-checkmark checkmark-container">
                <svg width="13" height="10" viewBox="0 0 13 10">
                  <polyline
                    fill="none"
                    className="checkmark"
                    strokeWidth="2"
                    points="17.532 8 10.02 15.512 7 12.492"
                    transform="translate(-6 -7)"
                  />
                </svg>
              </span>

            ) : (
              <span className="row-checkmark checkmark-container inactive"></span>
            )}




            {feature.active === true ? (
                <span className="feature">{feature.description}</span>
              ) : (
                <span className="feature inactve">{feature.description}</span>
              )}

              {feature.type ? (
                <div className="popover-label">
                  <span>{feature.type}</span>
                </div>
              ) : (
                ''
              )}
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default PersoneelPage
